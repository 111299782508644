import styled from '@emotion/styled';
import { Box, Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import {
    useTranslate,
    useDebounce,
    getHighlightedText,
} from '@mspecs/shared-utils';
import LoadingSpinner from '../../../../animations/loading-spinner-component';
import {
    BodyTextRegular,
    HighlightedBodyText,
    SubTextMedium,
} from '../../../../typograph/typograph-components';

const SuggestionContent = styled(Box, {
    shouldForwardProp: prop => !['hasResults'].includes(prop),
})`
    max-height: 300px;
    overflow-y: auto;
    ${({ hasResults, theme }) =>
        hasResults && `border: 1px solid ${theme.colors.borderPrimary}`};
`;
export const SuggestionText = styled(HighlightedBodyText)`
    padding: ${({ theme: { spacing } }) =>
        `${spacing.default} ${spacing.small}`};
    cursor: pointer;
    &:hover {
        background: ${({ theme }) => theme.colors.primaryColor10};
        border-radius: ${({ theme }) => theme.borderRadius.default};
    }
    font-weight: 400;
`;
const SuggestionResultText = styled(SubTextMedium)`
    padding: ${({ theme: { spacing } }) => `0 ${spacing.small}`};
`;

const SuggestionsWrapper = styled(Box)``;
const SpinnerWrapper = styled(Flex)`
    margin-top: ${({ theme }) => theme.spacing.default};
    align-items: center;
    padding: ${({ theme }) => theme.spacing.small};
`;
const LoadingText = styled(BodyTextRegular)`
    padding-left: ${({ theme }) => theme.spacing.small};
`;
const Content = styled(Box)`
    padding: ${({ theme }) => theme.spacing.small};
`;

const defaultSearchResultHeader = t => count => {
    return `${t('SEARCH_RESULTS!')}: ${t('GENERIC_COUNT', {
        count,
    })}`;
};

export const SearchInputSuggestions = ({
    isLoading,
    searchResult,
    selectedSuggestion,
    children,
    onSuggestionClick,
    searchString,
    getSearchResultHeader,
    ...props
}) => {
    const { t } = useTranslate();

    const hasResults =
        searchResult.length > 0 && !selectedSuggestion && !isLoading;

    const debouncedSearch = useDebounce(
        Array.isArray(searchString) ? searchString.join(' ') : searchString,
        700
    );

    const renderResultHeader =
        getSearchResultHeader || defaultSearchResultHeader(t);

    return (
        <SuggestionContent {...props} hasResults={hasResults}>
            {isLoading && (
                <Content>
                    <SpinnerWrapper>
                        <LoadingSpinner />
                        <LoadingText>{t('LOADING')}</LoadingText>
                    </SpinnerWrapper>
                </Content>
            )}
            {hasResults && (
                <Content>
                    {children}
                    <SuggestionResultText>
                        {renderResultHeader(searchResult.length)}
                    </SuggestionResultText>
                    <SuggestionsWrapper>
                        {searchResult.map((suggestion, index) => (
                            <Box
                                key={`${suggestion.value}_${index}`}
                                onClick={() => onSuggestionClick(suggestion)}
                            >
                                <SuggestionText>
                                    {getHighlightedText(
                                        suggestion.label,
                                        searchString
                                    )}
                                </SuggestionText>
                            </Box>
                        ))}
                    </SuggestionsWrapper>
                </Content>
            )}
            {!hasResults &&
                !isLoading &&
                debouncedSearch &&
                !selectedSuggestion && (
                    <Content>
                        <SubTextMedium>{t('NO_RESULT_MATCH')}</SubTextMedium>
                    </Content>
                )}
        </SuggestionContent>
    );
};
SearchInputSuggestions.propTypes = {
    children: PropTypes.any,
    isLoading: PropTypes.bool,
    searchResult: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })
    ),
    selectedSuggestion: PropTypes.string,
    onSuggestionClick: PropTypes.func,
    searchString: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default SearchInputSuggestions;
