import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';

import {
    BodyTextRegular,
    HighlightedBodyTextBold,
    SubTextMedium,
} from '../../typograph/typograph-components';
import { ErrorIcon } from '../../icon';
import { ITheme } from '../../../types';
import { InputFieldProps } from './input-field-component';
import { StyledPopover } from '../../popovers';

interface ExtendedPopoverProps {
    backgroundColor?: string;
}

const getInputPadding = (
    theme: ITheme,
    suffix?: string | undefined,
    isValid?: boolean | undefined
) => {
    const defaultPadding = theme.spacing.default;
    const suffixPadding = suffix
        ? `${20 + (suffix?.length - 1) * 6}px`
        : '10px';

    if (suffix) {
        return `${defaultPadding} ${suffixPadding} ${defaultPadding} ${defaultPadding}`;
    }

    return isValid
        ? `${defaultPadding} ${defaultPadding} ${defaultPadding} ${defaultPadding}`
        : `${defaultPadding} ${suffixPadding} ${defaultPadding} ${defaultPadding}`;
};

export const InputFieldWrapper = styled(Flex)`
    width: 100%;
    flex-direction: column;
    position: relative;
`;

export const InputWrapper = styled.div<{
    inputWidth?: string;
}>`
    width: 100%;
    position: relative;
    max-width: ${({ inputWidth }) =>
        inputWidth === 'small'
            ? '70px'
            : inputWidth === 'medium'
            ? '175px'
            : inputWidth === 'large'
            ? '271px'
            : 'initial'};
`;

export const Input = styled(
    forwardRef<
        any,
        InputFieldProps & {
            isValid?: boolean;
            theme?: ITheme;
        }
    >((props, ref) => <input ref={ref} {...props} />),
    {
        shouldForwardProp: prop =>
            ![
                'isValid',
                'saved',
                't',
                'hideRequiredHint',
                'stackedLabel',
                'subLabel',
                'initialValue',
                'isFormField',
                'hoverText',
                'additionalErrors',
                'validationFun',
                'parse',
            ].includes(prop),
    }
)`
    border: 1px solid
        ${({ theme, isValid, saved }) =>
            saved
                ? theme.colors.green40
                : isValid
                ? theme.colors.gray80
                : theme.colors.redPrimary};
    border-radius: ${({ theme }) => theme.borderRadius.default};
    height: 34px;
    width: 100%;
    padding: ${({ theme, suffix, isValid }) =>
        getInputPadding(theme, suffix, isValid)};
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    margin-bottom: 20px;
    font-size: 16px;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.m}) {
        font-size: 14px;
    }
    box-shadow: 0 0 0 2px
        ${({ theme, saved }) => (saved ? theme.colors.green15 : '0')};

    ::placeholder {
        color: ${({ theme }) => theme.colors.textSecondary};
    }
    &:hover {
        border-color: #bbbbbb;
    }
    &:focus {
        outline: none;
        border-color: ${({ theme, saved }) =>
            saved ? theme.colors.green40 : theme.colors.primaryColor60};
        box-shadow: 0 0 0 2px
            ${({ theme, saved }) =>
                saved ? theme.colors.green15 : theme.colors.primaryColor15};
    }
    &[disabled],
    &[readonly] {
        background-color: ${({ theme }) => theme.colors.bgSecondary};
        border: 1px solid ${({ theme }) => theme.colors.gray80};
        color: ${({ theme }) => theme.colors.textSecondary};

        :hover,
        :active,
        :focus {
            box-shadow: none;
        }
        :active,
        :focus {
            border: 1px solid ${({ theme }) => theme.colors.borderPrimary};
        }
    }
`;

export const InputHelpTextWrapper = styled(Flex)`
    justify-content: space-between;
    padding: 3px ${({ theme }: { theme: ITheme }) => theme.spacing.default};
    position: absolute;
    width: 100%;
    bottom: 0;
`;

export const ErrorIconWrapper = styled(ErrorIcon, {
    shouldForwardProp: prop => !['hasSuffix'].includes(prop),
})<{
    hasSuffix: boolean | undefined;
}>`
    position: absolute;
    top: 7px;
    right: ${({ hasSuffix }) => (hasSuffix ? '46px' : '10px')};
`;

export const LengthText = styled(SubTextMedium)`
    margin-left: auto;
    color: ${({
        theme,
        disabled,
        isOverdrawn,
    }: {
        theme?: ITheme;
        disabled?: boolean;
        isOverdrawn?: boolean;
    }) => {
        if (disabled) {
            return theme?.colors.textSecondary;
        }
        return isOverdrawn ? theme?.colors.errorColor : null;
    }};
`;

export const SubLabelText = styled(SubTextMedium)`
    margin-top: -20px; // compensate for margin-bottom on input
    padding: 2px ${({ theme }: { theme: ITheme }) => theme.spacing.default};
    color: ${({
        theme,
        disabled,
        invalid,
    }: {
        theme?: ITheme;
        disabled?: boolean;
        invalid?: boolean;
    }) =>
        disabled
            ? theme?.colors.textSecondary
            : invalid
            ? theme?.colors.errorColor
            : theme?.colors.textSecondary};
`;

export const Suffix = styled(BodyTextRegular)`
    position: absolute;
    top: 7px;
    right: 6px;
    color: ${({ theme }: { theme: ITheme }) => theme.colors.textSecondary};
`;

export const InputLabel = styled(
    HighlightedBodyTextBold.withComponent('label')
)`
    padding: ${({ theme }) =>
        `${theme.spacing.small} ${theme.spacing.default}`};
    display: block;
    color: ${({ theme, disabled }: { theme?: ITheme; disabled?: boolean }) =>
        disabled && theme?.colors.textSecondary};
`;

export const Popover = styled(StyledPopover)<ExtendedPopoverProps>`
    padding: 0;
    margin-right: 10px;
    .tippy-arrow {
        transform: none;
    }
`;

export const StyledHoverContent = styled(BodyTextRegular)`
    padding: 5px 10px 5px 10px;
    color: ${({ theme }: { theme: ITheme }) => theme.colors.white};
    border-radius: ${({ theme }: { theme: ITheme }) =>
        theme.borderRadius.medium};
`;

export const IconWrapper = styled.div`
    position: absolute;
    right: 6px;
    top: 7px;
`;
